import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}
// component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
let needRequiresAuth = false; // 是否需要检查 登录

// 引入 我自己的 routes
import { yRoutes } from "./yRoutes.js";
const routes = [
    //登录
    {
        path: "/login",
        name: "login",
        component: () => import("../views/login/login.vue")
    },
    {
        path: "/newHome",
        name: "newHome",
        component: () => import("../views/newHome/newHome.vue"),
        meta: {
            requiresAuth: needRequiresAuth
        }
    },

    // 弃用页面----start----
    {
        path: "/tongzhilist",
        name: "tongzhilist",
        component: () => import("../views/tongzhilist/tongzhilist.vue"),
        meta: {
            requiresAuth: needRequiresAuth
        }
    },
    {
        path: "/tongzhi",
        name: "tongzhi",
        component: () => import("../views/tongzhilist/tongzhi/tongzhi.vue"),
        meta: {
            requiresAuth: needRequiresAuth
        }
    },
    //注册
    {
        path: "/register",
        name: "register",
        component: () => import("../views/userPage/register.vue")
    },
    //验证码
    {
        path: "/verificationCode",
        name: "verificationCode",
        component: () => import("../views/userPage/verificationCode.vue")
    },
    //验证码
    {
        path: "/tesetPassword",
        name: "tesetPassword",
        component: () => import("../views/userPage/tesetPassword.vue")
    },
    //设置密码
    {
        path: "/setPassword",
        name: "setPassword",
        component: () => import("../views/userPage/setPassword.vue")
    },
    // 弃用页面----end----


    ...yRoutes,
];



const router = new VueRouter({
    mode: "hash",
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    // console.log("router", to);
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!checkUser()) {
            // next(); // 暂时关闭 登录
            next({
                path: "/login",
                query: {
                    redirect: to.fullPath
                }
            });
        } else {
            next();
        }
    } else {
        if(to.path=='/login'&&checkUser()){
            if(!from.matched.some(record => record.meta.requiresAuth)){
                next({
                    path: '/newHome',
                });
            }else{
                next({
                    path: from.fullPath,
                });
            }
        }else{
            next();
        }
    }
});

export default router;

function checkUser() {
    if (localStorage.accessToken) {
        return true;
    } else {
        let info = getUserInfo();
        if (!info) {
            return false;
        }
        return true;
    }
}

function getUserInfo() {
    try {
        let st = localStorage.userInfo;
        // console.log(st);
        let res = JSON.parse(localStorage.userInfo);
        global.accessToken = res.accessToken;
        global.userInfo = res;
        global.defaultToken = res.defaultToken;
        return res;
    } catch (e) {
        return null;
    }
}

export function logout() {
    global.accessToken = null;
    global.userInfo = null;
    global.defaultToken = null;

    localStorage.removeItem("defaultToken")
    localStorage.removeItem("accessToken")
    localStorage.removeItem("app")
    localStorage.removeItem("phone")
    localStorage.removeItem("userInfo");
    localStorage.removeItem("bindNumberTip");
    router.push({ path: "/login" });
}
